import { lazy } from 'react';
import {
  CUSTOMER_RECORD,
  HOME,
  LOGIN,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  LOGOUT,
  BUILDING,
  AGENCIES_ADMINISTRATION,
  INTERVENTIONS,
  ALL_INTERVENTIONS,
  VISIT_REPORT,
  BILLING,
  QUOTES_MANAGER,
  INVOICES_MANAGER,
  ALL_TODOS,
  ADMINISTRATION,
  MY_ACCOUNT,
  STATS,
  ACCESS_DENIED
} from '~/constants/internalLinks';

export const publicRoutes = [
  {
    path: LOGIN,
    exact: true,
    component: lazy(() => import('~/containers/auth/Login'))
  },
  {
    path: FORGOT_PASSWORD,
    exact: true,
    component: lazy(() => import('~/containers/auth/ForgotPassword'))
  },
  {
    path: RESET_PASSWORD,
    exact: true,
    component: lazy(() => import('~/containers/auth/ResetPassword'))
  },
  {
    path: LOGOUT,
    exact: true,
    component: lazy(() => import('~/containers/auth/Logout'))
  }
];

export const privateRoutes = [
  {
    path: HOME,
    exact: true,
    component: lazy(() => import('~/containers/home/Home'))
  },
  {
    path: CUSTOMER_RECORD,
    exact: true,
    component: lazy(() => import('~/containers/customerRecord/CustomerRecord'))
  },
  {
    path: BUILDING,
    exact: true,
    component: lazy(() => import('~/containers/building/Building'))
  },
  {
    path: AGENCIES_ADMINISTRATION,
    exact: true,
    component: lazy(
      () => import('~/containers/agenciesAdministration/AgenciesAdministration')
    )
  },
  {
    path: INTERVENTIONS,
    exact: true,
    component: lazy(() => import('~/containers/interventions/Interventions'))
  },
  {
    path: ALL_INTERVENTIONS,
    exact: true,
    component: lazy(() => import('~/containers/interventions/AllInterventions'))
  },
  {
    path: ALL_TODOS,
    exact: true,
    component: lazy(() => import('~/containers/allTodos'))
  },
  {
    path: VISIT_REPORT,
    exact: true,
    component: lazy(() => import('~/containers/visitReport/VisitReport'))
  },
  {
    path: BILLING,
    exact: true,
    component: lazy(() => import('~/containers/billing/Billing'))
  },
  {
    path: QUOTES_MANAGER,
    exact: true,
    component: lazy(() => import('~/containers/manager/Manager'))
  },
  {
    path: INVOICES_MANAGER,
    exact: true,
    component: lazy(() => import('~/containers/manager/Manager'))
  },
  {
    path: MY_ACCOUNT,
    exact: true,
    component: lazy(() => import('~/containers/myAccount'))
  },
  {
    path: ACCESS_DENIED,
    exact: true,
    component: lazy(() => import('~/containers/accessDenied'))
  }
];

export const adminRoutes = [
  {
    path: ADMINISTRATION,
    exact: false,
    component: lazy(() => import('~/routes/SubRoutes/AdministrationRoute'))
  }
];

export const statsRoutes = [
  {
    path: STATS,
    exact: false,
    component: lazy(() => import('~/routes/SubRoutes/StatsRoute'))
  }
];

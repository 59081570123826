import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  flexCenterCenter: {
    ...theme.mixins.flexCenterCenter
  },
  name: {
    fontSize: '19px',
    lineHeight: '22px',
    margin: '0',
    padding: '0 0.5rem'
  }
}));

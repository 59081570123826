import React from 'react';
import { MenuItem, IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import EuroOutlinedIcon from '@material-ui/icons/EuroOutlined';
import HoverMenu from 'material-ui-popup-state/HoverMenu';
import {
  usePopupState,
  bindHover,
  bindMenu
} from 'material-ui-popup-state/hooks';
import billManager_icon from '~/assets/icons/bill-manager.svg';
import { useStyles } from './NavBarMenusStyles';
import { strings } from '~/constants/strings';
import { INVOICES_MANAGER, QUOTES_MANAGER } from '~/constants/internalLinks';

function BillManagerNavMenu() {
  const classes = useStyles();

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'bill-manager-menu'
  });

  return (
    <>
      <IconButton
        aria-label="bill-manager"
        size="small"
        className={classes.iconBillManager}
        {...bindHover(popupState)}>
        <img
          style={{ marginTop: '2px', objectFit: 'scale-down' }}
          src={billManager_icon}
          alt="bill-manager"
          width="24px"
          height="24px"
        />
      </IconButton>

      <HoverMenu
        {...bindMenu(popupState)}
        disableScrollLock
        PaperProps={{
          elevation: 0,
          style: {
            boxShadow: '0 1px 5px 0px rgba(0, 0, 0, 0.2)',
            maxWidth: '350px'
          }
        }}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
        <div className={classes.menuCard}>
          <MenuItem
            component={Link}
            to={QUOTES_MANAGER}
            className={classes.menuButton}>
            <DescriptionOutlinedIcon
              style={{
                marginRight: '1rem',
                width: '22px',
                height: '22px',
                color: '#565E6B'
              }}
            />{' '}
            {strings.Gestionnaire_de_devis}
          </MenuItem>
          <MenuItem
            component={Link}
            to={INVOICES_MANAGER}
            className={classes.menuButton}>
            <EuroOutlinedIcon
              style={{
                marginRight: '1rem',
                width: '22px',
                height: '22px',
                color: '#565E6B'
              }}
            />{' '}
            {strings.Gestionnaire_de_factures}
          </MenuItem>
        </div>
      </HoverMenu>
    </>
  );
}

export default BillManagerNavMenu;

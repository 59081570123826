import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import getEnv from '~/utils/env';

const sentrydDSN = getEnv('SENTRY_DSN') || '';

export function LoggerInit() {
  Sentry.init({
    dsn: sentrydDSN,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  });
}

import { strings } from '~/constants/strings';
import { getNanoidNumber } from '~/utils/shared';
import {
  DisplayContactInfoDto,
  CreateEmployeeDto,
  DisplayBuildingBelongingDto
} from '~/services/api.types';
import { DisplayUserDto } from '~/services/directoryApi.types';

export const formattedOwnersList = (
  owners: DisplayBuildingBelongingDto[] = []
) => {
  const newCurrentOwnersList = owners.map((el) => ({
    ...el,
    id: getNanoidNumber(),
    type: !el?.endDate
      ? strings.proprietaire_actuel
      : strings.ancien_proprietaire,
    isCurrentOwner: !el?.endDate
  }));
  return newCurrentOwnersList;
};

export const formattedOccupantsList = (
  occupants: DisplayBuildingBelongingDto[] = []
) => {
  const newCurrentOccupantsList = occupants.map((el) => ({
    ...el,
    id: getNanoidNumber(),
    type: !el?.endDate ? strings.locataire_actuel : strings.ancien_locataire,
    isCurrentOccupant: !el?.endDate
  }));
  return newCurrentOccupantsList;
};

export const getContactLabel = (
  option: any,
  defaultAgcContact: number | null = null
) => {
  let icon = '';
  let personFunction = '';
  let holderType = ''; // owner or tenant
  if (defaultAgcContact === option?.id) {
    icon = '⭐';
  }
  if (option.isCurrentOwner) {
    icon = '🔸';
  }
  if (option.isCurrentOccupant) {
    icon = '🔹';
  }
  if (option?.personFunction) {
    personFunction = `[${option?.personFunction}]`;
  }
  if (option?.type) {
    holderType = `[${option?.type}]`;
  }
  return `${icon} ${option?.firstName ?? ''} ${
    option?.lastName ?? ''
  } ${personFunction} ${holderType}`;
};

export const getSimpleContactLabel = (option: CreateEmployeeDto) => {
  return `${option?.firstName ?? ''} ${option?.lastName ?? ''} [${
    option?.personFunction ?? ''
  }]`;
};

export const getSimpleGcAgentLabel = (option: DisplayUserDto | undefined) => {
  if (option) {
    return `${option?.firstName ?? ''} ${option?.lastName ?? ''}`;
  }
  return '';
};

export const getUserFullnameFirstLetter = (
  currentUser: DisplayContactInfoDto | null
) => {
  if (!currentUser) return '';
  const firstName = currentUser?.firstName;
  const lastName = currentUser?.lastName;
  const firstNameFirstLetter = firstName?.charAt(0)?.toUpperCase();
  const lastNameFirstLetter = lastName?.charAt(0)?.toUpperCase();
  const userLetters =
    firstName && lastName
      ? `${firstNameFirstLetter}${lastNameFirstLetter}`
      : firstName
      ? `${firstNameFirstLetter}`
      : lastName
      ? `${lastNameFirstLetter}`
      : '';
  return userLetters;
};

import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { LoadingSpinerProps } from './LoadingSpiner.types';

function LoadingSpinner({ small = false }: LoadingSpinerProps) {
  return (
    <CircularProgress
      style={{
        position: 'fixed',
        width: small ? '50px' : '75px',
        height: small ? '50px' : '75px',
        top: '50%',
        left: '50%'
      }}
      color="primary"
    />
  );
}

export default LoadingSpinner;

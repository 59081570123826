import jwtDecode from 'jwt-decode';
import { DIRECTORY, DIRECTORY_ACCOUNTS } from '~/constants/api';
import { TOKEN_KEY, REFRESH_TOKEN_KEY } from '~/constants/storageKeys';
import getEnv from '~/utils/env';
import {
  CreateResetPasswordDto,
  DisplayUserDto,
  PersonalTitle
} from './directoryApi.types';
import http from './httpService';

type Logins = {
  username: string;
  password: string;
};

export async function userLogin({ username, password }: Logins): Promise<any> {
  const loginVal = {
    grant_type: 'password',
    client_id: getEnv('LOGIN_CLIENT_ID'),
    username,
    password,
    client_secret: getEnv('LOGIN_CLIENT_SECRET')
  };
  const { data } = await http.post(
    `${DIRECTORY}/connect/token`,
    // @ts-ignore
    new URLSearchParams(loginVal)
  );
  return data;
}

export async function getNewAccessTokenWithRefreshToken(): Promise<any> {
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
  const refreshVal = {
    grant_type: 'refresh_token',
    client_id: getEnv('LOGIN_CLIENT_ID'),
    refresh_token: refreshToken,
    client_secret: getEnv('LOGIN_CLIENT_SECRET')
  };
  const { data } = await http.post(
    `${DIRECTORY}/connect/token`,
    // @ts-ignore
    new URLSearchParams(refreshVal)
  );
  return data;
}

type UserAccountMeResponse = {
  data: DisplayUserDto;
  status: number;
};

export async function getUserAccountMe(): Promise<UserAccountMeResponse> {
  const response = await http.get(`${DIRECTORY_ACCOUNTS}/me`);
  return response;
}

export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem(TOKEN_KEY);
    if (!jwt) return null;
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

export function decodeToken(jwt: any) {
  try {
    if (!jwt) return null;
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

interface AccountMeVal {
  user: {
    title: PersonalTitle;
    firstName: string;
    lastName: string;
    phoneNumber: string;
  };
}

export async function patchUserAccountMe(val: AccountMeVal) {
  const { data } = await http.patch(`${DIRECTORY_ACCOUNTS}/me`, {
    ...val,
    roles: ['admin']
  });
  return data;
}

export async function requestPasswordReset(email: string) {
  const { data } = await http.post(
    `${DIRECTORY_ACCOUNTS}/request-reset-password`,
    { email }
  );
  return data;
}

export async function resetPassword(val: CreateResetPasswordDto) {
  const { data } = await http.patch(
    `${DIRECTORY_ACCOUNTS}/reset-password`,
    val
  );
  return data;
}

export const CUSTOMERS = '/api/Customers';
export const CUSTOMER_GROUPS = '/api/CustomerGroups';
export const INTERVENTIONS = '/api/Interventions';
export const ACTIVITY_SECTORS = '/api/ActivitySectors';
export const BUILDINGS = '/api/Buildings';
export const AGENCIES = '/api/Agencies';
export const FILES = '/api/Files';
export const GC_QUOTES = '/api/GcQuotes';
export const GC_INVOICES = '/api/GcInvoices';
export const GC_DEPOSITS = '/api/Deposits';
export const GC_CREDIT_NOTES = '/api/CreditNotes';
export const ACCOUNTING = '/api/Accounting';
export const CORPORAMA_SEARCH = '/corporamaApi/prospect?v=2';
export const CORPORAMA_LEGAL = '/corporamaApi/legal?v=3';
export const GOOGLE_MAPS_API = 'https://maps.googleapis.com/maps/api/geocode';
export const DIRECTORY = '/directoryApi';
export const DIRECTORY_ACCOUNTS = '/directoryApi/api/Accounts';
export const DIRECTORY_USERS = '/directoryApi/api/Users';

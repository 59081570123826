import React, { memo } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { bindHover } from 'material-ui-popup-state/hooks';
import { getColor } from './shared';
import { getUserFullnameFirstLetter } from '~/containers/shared/contact/contactUtils';
import { MyAvatarProps } from './MyAvatar.types';
import { userInfoSelector } from '~/redux/features/user/userAuthSlice';
import { useAppSelector } from '~/redux/redux-hooks';

const MyAvatar = ({ popupState }: MyAvatarProps) => {
  const currentUser = useAppSelector(userInfoSelector);
  const firstLetter = currentUser?.firstName?.charAt(0)?.toUpperCase();
  return (
    <Avatar
      {...bindHover(popupState)}
      data-testid="avatar"
      variant="rounded"
      style={{
        backgroundColor: getColor(firstLetter),
        cursor: 'pointer'
      }}>
      {getUserFullnameFirstLetter(currentUser)}
    </Avatar>
  );
};

export default memo(MyAvatar);

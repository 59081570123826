import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  noData: {
    ...theme.typography.roboto_12_14,
    color: '#cfcfcf',
    textAlign: 'center',
    marginTop: 0,
    paddingLeft: '2px'
  },
  flexCenterCenter: {
    ...theme.mixins.flexCenterCenter,
    flexDirection: 'column'
  },
  errorOnFetching: {
    ...theme.typography.roboto_14_17,
    color: theme.palette.common.grayA8,
    textAlign: 'center',
    marginTop: '5px'
  }
}));

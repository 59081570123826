import React from 'react';
import {
  Typography,
  InputBase,
  IconButton,
  Tooltip,
  Badge
} from '@material-ui/core';
import {
  withStyles,
  fade,
  createStyles,
  Theme
} from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import TreeItem from '@material-ui/lab/TreeItem';
import CloseIcon from '@material-ui/icons/Close';
import MuiTableCell from '@material-ui/core/TableCell';
import { defaultColor } from '~/constants/colors';

export const TableCellCustom = withStyles({
  root: {
    borderBottom: 'none'
  }
})(MuiTableCell);

export const TableCellSmallPadding = withStyles({
  root: {
    borderBottom: 'none',
    padding: '6px 8px 6px 8px;'
  }
})(MuiTableCell);

export const TableCellBodyCustom = withStyles({
  root: {
    borderLeft: `1px solid ${defaultColor.grayMaterial}`,
    borderBottom: 'none',
    maxWidth: '200px'
  }
})(MuiTableCell);

interface DialogTitleProps {
  children: React.ReactNode;
  classes: any;
  onClose: () => void;
  [propName: string]: any;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  });

export const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...rest } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...rest}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export const StyledTreeItem = withStyles((theme) => ({
  iconContainer: {
    '& .close': {
      opacity: 0.3
    }
  },
  group: {
    marginLeft: 7,
    paddingLeft: 18,
    borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`
  }
}))((props: any) => <TreeItem {...props} />);

export const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

export const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center'
  }
}))(MuiDialogActions);

export const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: theme.palette.common.blue,
      boxShadow: `0 0 0 1px ${theme.palette.common.blue}`
    }
  }
}))(InputBase);

export const GrayTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.grayF9,
    ...theme.typography.roboto_13_15,
    fontWeight: '400',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 12,
    maxWidth: '500px',
    maxHeight: '300px',
    overflow: 'auto',
    whiteSpace: 'pre-line'
  }
}))(Tooltip);

export const BadgeWhiteTxt = withStyles((theme) => ({
  badge: {
    color: theme.palette.common.white
  }
}))(Badge);

import React, { useEffect } from 'react';
import './App.css';
import { Toaster } from 'react-hot-toast';
import Routes from './routes/Routes';
import ScrollToTop from './components/ScrollToTop';
import { fetchUserMe } from './redux/features/user/userAuthSlice';
import { useAppDispatch } from './redux/redux-hooks';
import { getCurrentUser } from './services/auth';

function App() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    // if the token is present, fetch user/me from te API
    if (getCurrentUser()) {
      dispatch(fetchUserMe());
    }
  }, [dispatch]);

  return (
    <>
      <Toaster />
      <Routes />
      <ScrollToTop />
    </>
  );
}

export default App;

import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Container } from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { useStyles } from './BackToStyles';
import { strings } from '~/constants/strings';
import {
  CUSTOMER_RECORD,
  INTERVENTIONS,
  AGENCIES_ADMINISTRATION,
  BUILDING,
  ALL_INTERVENTIONS,
  HOME,
  VISIT_REPORT,
  BILLING,
  QUOTES_MANAGER,
  INVOICES_MANAGER,
  ALL_TODOS,
  MY_ACCOUNT
} from '~/constants/internalLinks';

const BackTo = () => {
  const classes = useStyles();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const locaSplit = location.pathname.split('/').slice(1, 4);
  const customerId = urlParams.get('customerId');
  const buildingId = urlParams.get('buildingId');
  const isInvoice = Boolean(urlParams.get('invoiceSource'));
  const isDeposit = Boolean(
    urlParams.get('depositSource') || urlParams.get('depositInvoice')
  );
  const isCreditNote = Boolean(urlParams.get('creditNoteSource'));
  const pathName = locaSplit?.pop();

  let pathNameTrad;
  let pathUrl = '/';
  switch (pathName) {
    case CUSTOMER_RECORD.split('/')[1]:
      pathNameTrad = strings.fiche_client;
      break;
    case INTERVENTIONS.split('/')[1]:
      pathNameTrad = strings.Lignes_d_intervention;
      pathUrl = buildingId ? `${INTERVENTIONS}?customerId=${customerId}` : HOME;
      break;
    case AGENCIES_ADMINISTRATION.split('/')[1]:
      pathNameTrad = strings.administration_des_agences_attila;
      break;
    case BUILDING.split('/')[1]:
      pathNameTrad = strings.historique_du_batiment;
      pathUrl = `${INTERVENTIONS}?customerId=${customerId}`;
      break;
    case ALL_INTERVENTIONS.split('/')[1]:
      pathNameTrad = strings.Toutes_les_interventions;
      break;
    case ALL_TODOS.split('/')[1]:
      pathNameTrad = strings.Tous_les_actions_non_terminées;
      break;
    case VISIT_REPORT.split('/')[1]:
      pathNameTrad = strings.rapport_de_visite;
      pathUrl =
        customerId && buildingId
          ? `${INTERVENTIONS}?customerId=${customerId}&buildingId=${buildingId}`
          : '';
      break;
    case BILLING.split('/')[1]:
      pathNameTrad =
        isInvoice || isDeposit
          ? strings.facture
          : isCreditNote
          ? strings.Avoir
          : strings.Devis;
      pathUrl =
        customerId && buildingId
          ? `${INTERVENTIONS}?customerId=${customerId}&buildingId=${buildingId}`
          : '';
      break;
    case QUOTES_MANAGER.split('/')[1]:
      pathNameTrad = strings.Gestionnaire_de_devis;
      break;
    case INVOICES_MANAGER.split('/')[1]:
      pathNameTrad = strings.Gestionnaire_de_factures;
      break;
    case MY_ACCOUNT.split('/')[1]:
      pathNameTrad = strings.Mon_compte;
      break;
    default:
      pathNameTrad = '';
  }

  return (
    <Container maxWidth={false}>
      {pathNameTrad && (
        <div className={classes.backTo}>
          <Link className={classes.link} to={pathUrl}>
            <KeyboardArrowLeftIcon fontSize="large" />
            &nbsp; {pathNameTrad}
          </Link>
        </div>
      )}
    </Container>
  );
};

export default BackTo;

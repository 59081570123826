import { makeStyles, withStyles, Theme } from '@material-ui/core/styles';
import { Badge } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  menuCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '230px',
    textAlign: 'center',
    minWidth: '200px'
  },
  role: {
    color: '#1F2127',
    fontSize: '14px',
    lineHeight: '18px',
    margin: '0.5rem'
  },
  menuButton: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '20px',
    whiteSpace: 'nowrap',
    textTransform: 'none',
    justifyContent: 'flex-start',
    color: '#565E6B',
    textAlign: 'left',
    width: '100%'
  },
  iconNotification: {
    position: 'fixed',
    top: '20px',
    right: '80px'
  },
  iconBillManager: {
    position: 'fixed',
    top: '20px',
    right: '153px'
  },
  flexCenterCenter: {
    ...theme.mixins.flexCenterCenter
  },
  notificationBar: {
    color: theme.palette.common.blackMaterial,
    minWidth: '303px',
    height: '70px',
    marginBottom: '1rem',
    boxShadow:
      '0 0.46875rem 2.1875rem rgb(59 62 102 / 3%), 0 0.9375rem 1.40625rem rgb(59 62 102 / 3%), 0 0.25rem 0.53125rem rgb(59 62 102 / 5%), 0 0.125rem 0.1875rem rgb(59 62 102 / 3%)'
  },
  notificationCardDelete: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  notificationCardHeader: {
    background: '#00000003',
    color: '#908f9d',
    ...theme.mixins.flexCenterSpaceBetween,
    padding: '2px 5px'
  },
  notificationCard: {
    width: '280px',
    margin: '5px 8px 5px 5px',
    borderRadius: '4px',
    boxShadow:
      '0px 3px 3px -2px rgb(0 0 0 / 6%), 0px 3px 4px 0px rgb(0 0 0 / 4%), 0px 1px 8px 0px rgb(0 0 0 / 4%);'
  },
  btnTransparent: {
    ...theme.mixins.btnTransparent,
    ...theme.typography.btnTransparent
  }
}));

export const BadgeRed = withStyles((theme: Theme) => ({
  badge: {
    color: theme.palette.common.white,
    backgroundColor: '#B70E0C'
  }
}))(Badge);

/* eslint-disable no-else-return */
import { nanoid } from 'nanoid';
import {
  endOfMonth,
  startOfMonth,
  subMonths,
  differenceInSeconds
} from 'date-fns';
import { customAlphabet } from 'nanoid/non-secure';
import {
  allowedImgType,
  allowedImgExtension,
  allowedDocType,
  allowedDocExtension
} from '~/constants/files';
import { strings } from '~/constants/strings';
import { TEMPORARY_ID } from '~/constants/shared';
import { Roles } from '~/redux/features/user/userAuthSlice';

export function isBoolean(val: any) {
  return val === false || val === true;
}

export function isNumber(value: any) {
  return typeof value === 'number' && isFinite(value);
}

export function convertIfNumber(value: any) {
  return isNumber(value) ? Number(value) : value;
}

export function isString(value: any) {
  return typeof value === 'string';
}

// STRING
export const toNormalizeAndLower = (str: any) => {
  if (isString(str)) {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      ?.toLowerCase();
  }
  return str;
};

export function capitalizeFirstLetter(string: string) {
  const lower = string.toLowerCase();
  return lower.charAt(0).toUpperCase() + lower.slice(1);
}
type DateInput = Date | string | number;
// DATE
export const dateWithoutTime = (date: DateInput) =>
  new Date(new Date(date).toDateString());

export const currentYearStart = new Date(
  `${new Date().getFullYear()}-01-01T00:00:00`
);
export const currentYearEnd = new Date(
  `${new Date().getFullYear()}-12-31T23:59:59`
);

export const currentMonthStart = new Date(startOfMonth(new Date()));
export const currentMonthEnd = new Date(endOfMonth(new Date()));

export const lastYearStart = new Date(
  `${new Date().getFullYear() - 1}-01-01T00:00:00`
);
export const lastYearEnd = new Date(
  `${new Date().getFullYear() - 1}-12-31T23:59:59`
);

export const lastMonthStart = new Date(subMonths(currentMonthStart, 1));
export const lastMonthEnd = new Date(subMonths(currentMonthEnd, 1));

export const getEndOfTheDayDate = (date: DateInput) => {
  const newEndDate = new Date(date);
  newEndDate.setHours(23, 59, 59);
  return newEndDate;
};

export const setHoursToDate = (date: DateInput, hour: number) => {
  const newEndDate = new Date(date);
  newEndDate.setHours(hour);
  return newEndDate;
};

export const getPreviousDayDate = (date: DateInput) => {
  const newEndDate = new Date(date);
  newEndDate.setDate(newEndDate.getDate() - 1);
  return newEndDate;
};

export const removeSecondsFromDate = (date: DateInput) => {
  const newEndDate = new Date(date);
  newEndDate.setSeconds(0);
  return newEndDate;
};

export const isValidDate = (date: any) => {
  return !isNaN(date) && date instanceof Date;
};

export const urlDateFormat = (date: Date) =>
  `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

export function getTimeDifference(date: Date) {
  const difference = differenceInSeconds(new Date(), date);

  if (difference < 60) return `${Math.floor(difference)} sec`;
  else if (difference < 3600) return `${Math.floor(difference / 60)} min`;
  else if (difference < 86400) return `${Math.floor(difference / 3660)} h`;
  else if (difference < 86400 * 30)
    return `${Math.floor(difference / 86400)} J`;
  else if (difference < 86400 * 30 * 12)
    return `${Math.floor(difference / 86400 / 30)} M`;
  else return `${(difference / 86400 / 30 / 12).toFixed(1)} An`;
}

export const isNumericInput = (val: any) => {
  const rgx = /^[0-9]*\.?[0-9]*$/;
  return val.match(rgx);
};

export const roundNum = (num: number | string, precision = 2) => {
  // toFixed only, doesn't give a correct round // precision 1 or 2
  const roundUp = (Math.round(Number(num) * 100) / 100).toFixed(precision);
  return Number(roundUp);
};

export const roundNumNDecimal = (num: number | string, precision = 2) => {
  return (
    Math.round((+num + Number.EPSILON) * 10 ** precision) / 10 ** precision
  );
};

export const numberToFixed = (
  number: number | string,
  fraction: number = 2
) => {
  const numberFormat = Number(number).toFixed(fraction);
  return numberFormat;
};

// toLocaleString not working with react-pdf (strange symbol appear)
export const numberWithSpacesAndxFractionDigits = (
  number: number | string,
  fraction: number = 2
) => {
  const numberFormat = numberToFixed(number, fraction);
  const parts = numberFormat.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return parts.join('.');
};

export const getNextDayDate = (date: Date) => {
  const newEndDate = new Date(date);
  newEndDate.setDate(newEndDate.getDate() + 1);
  return newEndDate;
};

export const randomColorFromPalette = (index: number = 0) => {
  const isOdd = index % 2;
  const preselectedColorsBright = [
    '#e4d306',
    '#c6b800',
    '#a59b00',
    '#827c00',
    '#5e5b00',
    '#743abd',
    '#5a22a3',
    '#3a0085',
    '#4d44a1',
    '#5d5dca',
    '#00008a',
    '#7d3442', // brown palette
    '#60243a',
    '#8d7379'
  ];
  const preselectedColorsSoft = [
    '#d247e2',
    '#bc2ecd',
    '#a100b3',
    '#9d9edf',
    '#800093',
    '#9541a8',
    '#e196ff',
    '#e7148b',
    '#cb0074',
    '#aa0059',
    '#fda8cf'
  ];
  const preselectedColors = index
    ? isOdd
      ? preselectedColorsSoft
      : preselectedColorsBright
    : [...preselectedColorsBright, ...preselectedColorsSoft];

  const randomColor =
    preselectedColors[Math.floor(Math.random() * preselectedColors.length)];
  return randomColor;
};

// to use it getNanoidNumber
export const getNanoidNumber = () => {
  const generateId = customAlphabet('1234567890', 16);
  return Number(generateId());
};

type fileType = 'IMG' | 'DOC';

export const checkIfFileTypeAllowed = (
  file: any,
  type: fileType,
  fnMessage: (text: string) => void
) => {
  let allowedType: string[] = [];
  let allowedExtension: string[] = [];
  if (type === 'IMG') {
    allowedType = allowedImgType;
    allowedExtension = allowedImgExtension;
  }
  if (type === 'DOC') {
    allowedType = allowedDocType;
    allowedExtension = allowedDocExtension;
  }
  fnMessage('');
  const validTypesFormat = allowedType;
  const typeFormat = file.type;
  const validTypes = allowedExtension;
  const ext = file.name.split('.').pop();
  if (
    (ext && validTypes.indexOf(ext.toString().toLowerCase()) === -1) ||
    validTypesFormat.indexOf(typeFormat) === -1
  ) {
    fnMessage(
      `${strings.extension_fichier_non_autorisee_etc}: ${allowedExtension.join(
        ', '
      )}`
    );
    return false;
  }
  return true;
};

export const generateTemporaryInternalId = () => `${TEMPORARY_ID}${nanoid()}`;

export const detectInternalTemporaryId = (str: string | number | undefined) => {
  // we use internal id as key for data manipulation before submit, DB Id are number only
  // internal id are string starting by temporaryId- , example temporaryId-H1etGXR8_S5jd4i6B-myT
  return typeof str === 'string' && str.indexOf(TEMPORARY_ID) === 0;
};

export const detectRealDbId = (str: string | number | undefined) => {
  const isRealId =
    typeof str !== 'string' ||
    (typeof str === 'string' && str.indexOf(TEMPORARY_ID) !== 0);
  return isRealId;
};

export const eventBus = {
  on(event: any, callback: any) {
    document.addEventListener(event, (e) => callback(e.detail));
  },
  dispatch(event: any, data?: any) {
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  },
  remove(event: any, callback?: any) {
    document.removeEventListener(event, callback);
  }
};

export const smoothScrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

export const getUserRoleTranslation = (
  userRole: Roles | string | null | undefined
) => {
  let userRoleFr;
  switch (userRole) {
    case Roles.Admin:
      userRoleFr = strings.Responsable_Grands_comptes;
      break;
    case Roles.Accountant:
      userRoleFr = strings.Comptable;
      break;
    case Roles.User:
      userRoleFr = strings.Assistant_e_commerciale_Grands_comptes;
      break;
    default:
      userRoleFr = '';
  }
  return userRoleFr;
};

export const geoDistanceCalc = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
) => {
  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;
  const theta = lon1 - lon2;
  const radtheta = (Math.PI * theta) / 180;
  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  if (dist > 1) {
    dist = 1;
  }
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  // distance in KM
  dist *= 1.609344;
  return dist;
};

export function getLocalISOString(date: Date) {
  const offset = date.getTimezoneOffset();
  const offsetAbs = Math.abs(offset);
  const isoString = new Date(date.getTime() - offset * 60 * 1000).toISOString();
  return `${isoString.slice(0, -1)}${offset > 0 ? '-' : '+'}${String(
    Math.floor(offsetAbs / 60)
  ).padStart(2, '0')}:${String(offsetAbs % 60).padStart(2, '0')}`;
}

import { useState, useEffect } from 'react';
import axios from 'axios';
import http from '~/services/httpService';
import { ToastError } from '~/components/Toast/ToastError';

const useFetch = (url: string, immediate = true, hasToRefetch = false) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const doFetch = () => {
      setIsLoading(true);
      setError(null);
      http
        .get(url, { cancelToken: source.token })
        .then((res) => {
          setIsLoading(false);
          res.data && setData(res.data);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setIsLoading(false);
            setError(`An error occurred.', ${err}`);
            ToastError(err);
          }
        });
    };
    immediate && doFetch();
    hasToRefetch && doFetch();

    return () => {
      source.cancel();
    };
  }, [url, immediate, hasToRefetch]);

  return { data, isLoading, error };
};

export default useFetch;

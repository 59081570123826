import React from 'react';
import { strings } from '~/constants/strings';
import { numberWithSpacesAndxFractionDigits } from '~/utils/shared';
import { RevenuesStatsProps } from '../Header.types';

const RevenuesStats = ({
  classes,
  interventionsRevenues
}: RevenuesStatsProps) => {
  return (
    <>
      {/* monthly revenues */}
      <div>
        <p className={`${classes.headerAmount} ${classes.purple}`}>
          {numberWithSpacesAndxFractionDigits(
            Number(interventionsRevenues?.monthlyRevenues),
            0
          )}{' '}
          €
        </p>
        <p className={classes.amountDescription}>
          {strings.CA_du_mois_en_cours}
        </p>
      </div>
      {/* monthly ce revenues */}
      <div>
        <p className={`${classes.headerAmount} ${classes.purple}`}>
          {numberWithSpacesAndxFractionDigits(
            Number(interventionsRevenues?.monthlyCeRevenues),
            0
          )}{' '}
          €
        </p>
        <p className={classes.amountDescription}>
          {strings.CA_du_mois_en_cours_contrats_d_entretien}
        </p>
      </div>
      {/* monthly general revenues */}
      <div>
        <p className={`${classes.headerAmount} ${classes.purple}`}>
          {numberWithSpacesAndxFractionDigits(
            Number(interventionsRevenues?.monthlyGeneralRevenues),
            0
          )}{' '}
          €
        </p>
        <p className={classes.amountDescription}>
          {strings.CA_du_mois_en_cours_curatif}
        </p>
      </div>
      {/* total revenues */}
      <div>
        <p className={`${classes.headerAmount} ${classes.purple}`}>
          {numberWithSpacesAndxFractionDigits(
            Number(interventionsRevenues?.cumulativeRevenues),
            0
          )}{' '}
          €
        </p>
        <p className={classes.amountDescription}>
          {strings.Chiffre_d_affaire_cumulé}
        </p>
      </div>
    </>
  );
};

export default RevenuesStats;

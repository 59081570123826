import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { DisplayUserDto } from '~/services/directoryApi.types';
import { getGcAgents } from '~/services/administration';
import { RootState } from '../../store';

interface State {
  agents: DisplayUserDto[] | null | undefined;
  isLoading: boolean;
  hasError: boolean;
}

const initialState: State = {
  agents: null,
  isLoading: false,
  hasError: false
};

export const fetchGcAgents = createAsyncThunk(
  'gcAgents/fetchGcAgents',
  async () => {
    const gcAgents = await getGcAgents();
    return gcAgents;
  }
);

const gcAgentsSlice = createSlice({
  name: 'gcAgents',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGcAgents.fulfilled, (state, action) => {
      state.agents = action.payload.values;
      state.isLoading = false;
      state.hasError = false;
    });
    builder.addCase(fetchGcAgents.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchGcAgents.rejected, (state) => {
      state.isLoading = false;
      state.hasError = true;
    });
  }
});

// we use create selector to get cached fast response (it like reselect)
export const gcAgentsSelector = (state: RootState) => state.gcAgents.agents;
export const gcAgentsLoaderSelector = (state: RootState) =>
  state.gcAgents.isLoading;
export const gcAgentsFetchingHasError = (state: RootState) =>
  state.gcAgents.hasError;

export default gcAgentsSlice.reducer;

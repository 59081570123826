/* eslint-disable */
import React, { Component, ErrorInfo, ReactNode } from 'react';
import { HOME, LOGOUT } from '~/constants/internalLinks';
import './ErrorBoundary.css';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  public static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <div className="eb-center">
          <h3>Ooops, la page n'a pas pu être chargée</h3>
          <p>
            <b>FR: </b>Veuillez recharger la page
          </p>
          <p>
            <b>EN: </b>Please, reload the page
          </p>
          <button
            type="button"
            className="eb-btn-blue"
            onClick={() => window.location.reload()}>
            Recharger / Reload
          </button>
          <p>
            ou essayez l'une de ces options / or try one of these options:{' '}
            <a className="eb-text-blue" href={HOME}>
              page d'accueil
            </a>{' '}
            ou{' '}
            {/* the logout function is putted outside the error boundary. to left this page clean */}
            <a className="eb-text-blue" href={LOGOUT}>
              se déconnecter
            </a>
          </p>
          <hr />
          <p>
            <br />
            <b>FR: </b>si le problème persiste nous vous recommandons de{' '}
            <a className="eb-text-blue" href={LOGOUT}>
              se déconnecter
            </a>
            , puis se reconnecter
          </p>
          <p>
            <br />
            <b>EN: </b>if the problem persists, we recommend you to{' '}
            <a className="eb-text-blue" href={LOGOUT}>
              logout
            </a>
            , then login again
          </p>
        </div>
      );
    }

    return children;
  }
}
export default ErrorBoundary;

import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { MenuItem } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { usePopupState, bindMenu } from 'material-ui-popup-state/hooks';
import HoverMenu from 'material-ui-popup-state/HoverMenu';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import MyAvatar from '../../Avatars';
import { useStyles } from './NavBarMenusStyles';
import { strings } from '~/constants/strings';
import MyAvatarChip from '../../Avatars/MyAvatarChip';
import { logout, userRoleSelector } from '~/redux/features/user/userAuthSlice';
import { useAppDispatch, useAppSelector } from '~/redux/redux-hooks';
import { LOGIN, MY_ACCOUNT } from '~/constants/internalLinks';
import { getUserRoleTranslation } from '~/utils/shared';

function ProfileNavMenu() {
  const userRole = useAppSelector(userRoleSelector);
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const userRoleFR = getUserRoleTranslation(userRole);

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'profile-menu'
  });

  const handleLogout = () => {
    dispatch(logout());
    history.push(LOGIN);
  };

  return (
    <>
      <MyAvatar popupState={popupState} />
      <HoverMenu
        {...bindMenu(popupState)}
        disableScrollLock
        PaperProps={{
          elevation: 0,
          style: {
            boxShadow: '0 1px 5px 0px rgba(0, 0, 0, 0.2)',
            maxWidth: '350px'
          }
        }}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
        <div className={classes.menuCard}>
          <MyAvatarChip />
          <p className={classes.role}>{userRoleFR}</p>
          <div
            style={{
              width: '100%',
              height: '1px',
              background: '#ddd',
              marginTop: '1rem'
            }}
          />
          <MenuItem
            className={classes.menuButton}
            component={Link}
            to={MY_ACCOUNT}>
            <AssignmentIndIcon style={{ marginRight: '1rem' }} />{' '}
            {strings.Mon_compte}
          </MenuItem>
          <MenuItem className={classes.menuButton} onClick={handleLogout}>
            <ExitToAppIcon style={{ marginRight: '1rem' }} />{' '}
            {strings.Se_deconnecter}
          </MenuItem>
        </div>
      </HoverMenu>
    </>
  );
}

export default ProfileNavMenu;

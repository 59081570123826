import http from './httpService';
import { BUILDINGS, INTERVENTIONS } from '~/constants/api';
import useFetch from '~/hooks/useFetch';
import {
  PagedResultDtoOfDisplayInterventionDto,
  CreateInterventionDto,
  DisplayInterventionDto,
  CreateCommentDto,
  UpdateSavWorkStartDate,
  Sav,
  CreateSavDto,
  DisplaySingleInterventionDto,
  CreateInterventionTaskDto,
  PagedResultDtoOfDisplayInterventionTaskDto,
  UpdateCommentDto,
  DisplayInterventionTaskDto,
  QuoteType,
  CreateComplementaryInterventionDto,
  DisplayRevenuesDto,
  DisplayTodoStatsDto,
  CreateInvoicePaymentAuthorizationDto
} from './api.types';
import {
  BuildingInterventionResponse,
  CreateMessageWithDateDto,
  ReminderDto
} from '~/containers/interventions/Interventions.types';

interface InterventionsFindResponse {
  data: PagedResultDtoOfDisplayInterventionDto | null;
  isLoading: boolean;
}

export const useFetchInterventions = (
  rowsPerPage: number,
  rowToSkip: number,
  apiQuerySearchParams: string,
  hasToRefetch: boolean
): InterventionsFindResponse => {
  const { data, isLoading } = useFetch(
    `${INTERVENTIONS}?Take=${rowsPerPage}&Skip=${rowToSkip}&${apiQuerySearchParams}`,
    true,
    hasToRefetch
  );
  return { data, isLoading };
};

interface InterventionsFindByRefResponse {
  interventionsByReference: PagedResultDtoOfDisplayInterventionDto | null;
  isLoading: boolean;
  errorInterventionsByReference: string | null;
}

export const useFetchInterventionsByReference = (
  isInvoiceRelated: boolean,
  reference: string | null
): InterventionsFindByRefResponse => {
  const referenceType = isInvoiceRelated
    ? 'GcInvoiceReference'
    : 'GcQuoteReference';
  const {
    data: interventionsByReference,
    isLoading,
    error: errorInterventionsByReference
  } = useFetch(`${INTERVENTIONS}?${referenceType}=${reference}`, !!reference);
  return { interventionsByReference, isLoading, errorInterventionsByReference };
};

export async function createIntervention(
  val: CreateInterventionDto
): Promise<DisplayInterventionDto> {
  const { data } = await http.post(INTERVENTIONS, val);
  return data;
}

export async function addOrderToIntervention(
  id: number,
  val: CreateMessageWithDateDto
): Promise<number> {
  const { data } = await http.post(`${INTERVENTIONS}/${id}/order`, val);
  return data;
}

export async function addCommentToIntervention(
  id: number,
  val: CreateCommentDto
): Promise<Comment> {
  const { data } = await http.post(`${INTERVENTIONS}/${id}/comment`, val);
  return data;
}

export async function deleteIntervention(id: number): Promise<number> {
  const { data } = await http.delete(`${INTERVENTIONS}/${id}`);
  return data;
}

export async function interventionDeleteFile(
  id: number,
  path: string,
  fileId: string = ''
): Promise<number> {
  const { data } = await http.delete(
    `${INTERVENTIONS}/${id}/${path}/${fileId}`
  );
  return data;
}

export async function interventionDeleteSavFile(
  key: string,
  id: number,
  savId: number,
  fileId: string
): Promise<number> {
  const path = key === 'files' ? 'file' : 'reception-record';
  const { data } = await http.delete(
    `${INTERVENTIONS}/${id}/sav/${savId}/${path}/${fileId}`
  );
  return data;
}

export async function interventionDeleteSav(
  id: number,
  savId: number
): Promise<number> {
  const { data } = await http.delete(`${INTERVENTIONS}/${id}/sav/${savId}`);
  return data;
}

export async function interventionUpdateSavWorkStartDate(
  id: number,
  savId: number,
  val: UpdateSavWorkStartDate
): Promise<Sav> {
  const { data } = await http.put(
    `${INTERVENTIONS}/${id}/sav/${savId}/work-start-date`,
    val
  );
  return data;
}

export async function interventionAddSavDate(
  id: number,
  val: CreateSavDto
): Promise<Sav> {
  const { data } = await http.post(`${INTERVENTIONS}/${id}/sav`, val);
  return data;
}

export async function uploadInterventionFile(
  endpoint: string,
  formData: FormData
): Promise<string> {
  const { data } = await http.post(endpoint, formData);
  return data;
}

export async function updateInterventionIsVisitReportRequired(
  id: number,
  val: boolean
): Promise<number> {
  const { data } = await http.patch(`${INTERVENTIONS}/${id}`, {
    isVisitReportRequired: val
  });
  return data;
}

export async function updateInterventionPreventionPlanStatus(
  id: number,
  val: boolean
): Promise<number> {
  const { data } = await http.patch(`${INTERVENTIONS}/${id}`, {
    hasPreventionPlan: val
  });
  return data;
}

export async function updateInterventionAssignedAgency(
  id: number,
  agencyId: number
): Promise<number> {
  const { data } = await http.patch(
    `${INTERVENTIONS}/${id}/assign-agency/${agencyId}`
  );
  return data;
}

interface InterventionByIdFindResponse {
  intervention: DisplaySingleInterventionDto | null;
  isLoading: boolean;
  error: string | null;
}

export const useFetchInterventionById = (
  interventionId: number,
  hasToRefetch: boolean = false
): InterventionByIdFindResponse => {
  const {
    data: intervention,
    isLoading,
    error
  } = useFetch(
    `${INTERVENTIONS}/${interventionId}`,
    !!interventionId,
    hasToRefetch
  );

  return { intervention, isLoading, error };
};

interface InterventionsOrBuildingInterventionsFindResponse {
  interventionsData: BuildingInterventionResponse | null;
  isLoadingInterventions: boolean;
  errorFetchingInterventions: string | null;
}

export const useFetchInterventionsOrBuildingInterventions = (
  hasFilter: boolean,
  querySearchParams: string,
  buildingId: number,
  interventionsFromBuildingHistory: any[] | undefined,
  hasToRefetch: boolean
): InterventionsOrBuildingInterventionsFindResponse => {
  const {
    data: interventionsData,
    isLoading: isLoadingInterventions,
    error: errorFetchingInterventions
  } = useFetch(
    hasFilter
      ? `${INTERVENTIONS}?${querySearchParams}`
      : `${BUILDINGS}/${buildingId}/interventions`,
    (!!buildingId && !interventionsFromBuildingHistory) || hasFilter,
    hasToRefetch
  );

  return {
    interventionsData,
    isLoadingInterventions,
    errorFetchingInterventions
  };
};

export async function addTodo(
  id: number,
  val: CreateInterventionTaskDto
): Promise<DisplayInterventionTaskDto> {
  const { data } = await http.post(`${INTERVENTIONS}/${id}/todo`, val);
  return data;
}

interface InterventionsTodosFindReponse {
  data: PagedResultDtoOfDisplayInterventionTaskDto | null;
  isLoading: boolean;
}

export const useFetchInterventionTodos = (
  interventionId: number,
  rowsPerPage: number,
  rowToSkip: number
): InterventionsTodosFindReponse => {
  const { data, isLoading } = useFetch(
    `${INTERVENTIONS}/${interventionId}/todo?Take=${rowsPerPage}&Skip=${rowToSkip}`,
    true
  );
  return { data, isLoading };
};

export async function updateTodoComment(
  interventionId: number,
  todoId: number,
  val: UpdateCommentDto
): Promise<DisplayInterventionTaskDto> {
  const { data } = await http.patch(
    `${INTERVENTIONS}/${interventionId}/todo/${todoId}/comment`,
    val
  );
  return data;
}

export async function getTodoCount(interventionId: number): Promise<number> {
  const { data } = await http.get(
    `${INTERVENTIONS}/${interventionId}/todo/count`
  );
  return data;
}

export async function restoreDeletedIntervention(
  interventionId: number
): Promise<number> {
  const { data } = await http.patch(
    `${INTERVENTIONS}/${interventionId}/restore`
  );
  return data;
}

export async function createReminder(
  interventionId: number,
  val: ReminderDto
): Promise<number> {
  const { data } = await http.put(
    `${INTERVENTIONS}/${interventionId}/reminder`,
    val
  );
  return data;
}

export async function createInterventionFollowingCe(
  id: number,
  type: string,
  val: CreateComplementaryInterventionDto
) {
  let followingType = '';
  if (type === QuoteType.SupplementaryCe) {
    followingType = 'supplementary-ce';
  }
  if (type === QuoteType.MinorWorks) {
    followingType = 'minor-works';
  }

  const { data } = await http.post(
    `${INTERVENTIONS}/${id}/${followingType}`,
    val
  );
  return data;
}

export async function editIntervention(id: number, val: any): Promise<number> {
  const { data } = await http.patch(`${INTERVENTIONS}/${id}`, val);
  return data;
}

export async function getTodoStats(): Promise<DisplayTodoStatsDto> {
  const { data } = await http.get(`${INTERVENTIONS}/todo/stats`);
  return data;
}

export async function getInterventionsRevenues(): Promise<DisplayRevenuesDto> {
  const { data } = await http.get(`${INTERVENTIONS}/revenues`);
  return data;
}

export async function postAgencyPaymentAuthorization(
  interventionId: number,
  agencyInvoiceId: number,
  val: CreateInvoicePaymentAuthorizationDto
): Promise<number> {
  const { data } = await http.post(
    `${INTERVENTIONS}/${interventionId}/agency-invoices/${agencyInvoiceId}/payment-authorization`,
    val
  );
  return data;
}

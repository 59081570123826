import { DisplayUserDto } from '~/services/directoryApi.types';

export const getColor = (firstLetter: string | undefined) => {
  let color = '#d8d8d8';
  if (!firstLetter) return color;

  if (['A', 'B'].indexOf(firstLetter) !== -1) {
    color = '#5AACCF';
  }
  if (['C', 'D'].indexOf(firstLetter) !== -1) {
    color = '#92B0D9';
  }
  if (['E', 'F'].indexOf(firstLetter) !== -1) {
    color = '#a197f1';
  }
  if (['G', 'H'].indexOf(firstLetter) !== -1) {
    color = '#8DA2DD';
  }
  if (['I', 'J'].indexOf(firstLetter) !== -1) {
    color = '#5db3a6';
  }
  if (['K', 'L'].indexOf(firstLetter) !== -1) {
    color = '#3e74ca';
  }
  if (['M', 'N'].indexOf(firstLetter) !== -1) {
    color = '#a9d675';
  }
  if (['O', 'P'].indexOf(firstLetter) !== -1) {
    color = '#D992C6';
  }
  if (['Q', 'R'].indexOf(firstLetter) !== -1) {
    color = '#e4a24e';
  }
  if (['S', 'T'].indexOf(firstLetter) !== -1) {
    color = '#ce9064';
  }
  if (['U', 'V'].indexOf(firstLetter) !== -1) {
    color = '#c09e6d';
  }
  if (['W', 'X'].indexOf(firstLetter) !== -1) {
    color = '#67bec0';
  }
  if (['Y', 'Z'].indexOf(firstLetter) !== -1) {
    color = '#bbc792';
  }
  return color;
};

export const getUserLetters = (user: DisplayUserDto) => {
  let firstNameLetter = '';
  let lastNameLetter = '';
  let firstCharacterOfName;

  if (user?.firstName) {
    firstNameLetter = `${user.firstName.charAt(0).toUpperCase()}`;
  }
  if (user?.lastName) {
    lastNameLetter = `${user.lastName
      ?.split(' ')
      .pop()
      ?.charAt(0)
      .toUpperCase()}`;
  }

  switch (true) {
    case !!user?.firstName && !!user?.lastName:
      firstCharacterOfName = `${firstNameLetter}${lastNameLetter}`;
      break;
    case !!user?.firstName:
      firstCharacterOfName = `${firstNameLetter}`;
      break;
    case !!user?.lastName:
      firstCharacterOfName = `${lastNameLetter}`;
      break;
    default:
      firstCharacterOfName = '';
  }

  return firstCharacterOfName;
};

import React from 'react';
import { StatsProps } from '../Header.types';
import RevenuesStats from './RevenuesStats';
import TodoStats from './TodoStats';

const Stats = (props: StatsProps) => {
  const { classes, interventionsRevenues, todoStats, hasToDisplayMoreInfo } =
    props;
  return (
    <div className={classes.numbersBox}>
      {!hasToDisplayMoreInfo && (
        <TodoStats classes={classes} todoStats={todoStats} />
      )}
      {hasToDisplayMoreInfo && (
        <RevenuesStats
          classes={classes}
          interventionsRevenues={interventionsRevenues}
        />
      )}
    </div>
  );
};

export default Stats;

import React from 'react';
import { CssBaseline } from '@material-ui/core';
import { isAuthenticatedSelector } from '~/redux/features/user/userAuthSlice';
import Navbar from './Navbar';
import BackTo from '../BackTo';
import { useAppSelector } from '~/redux/redux-hooks';

const Header = () => {
  const isAuthenticated = useAppSelector(isAuthenticatedSelector);

  if (isAuthenticated)
    return (
      <>
        <CssBaseline />
        <Navbar />
        <BackTo />
      </>
    );
  return <></>;
};

export default Header;

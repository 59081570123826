import React, { useState, useRef, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Dialog, Button, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useStyles } from './AuthStyles';
import { DialogContent, DialogActions } from '~/theme/Shared';
import { strings } from '~/constants/strings';
import {
  hasAuthErrorSelector,
  authErrorMessageSelector,
  loginUser,
  isAuthLoadingSelector,
  logout
} from '~/redux/features/user/userAuthSlice';
import { useAppDispatch, useAppSelector } from '~/redux/redux-hooks';
import { LoginModalProps } from './auth.types';
import { LOGIN } from '~/constants/internalLinks';

export default function ReLoginModal(props: LoginModalProps) {
  const { open, handleClose } = props;
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const classes = useStyles();
  const formRef = useRef<HTMLFormElement | null>(null);
  const hasAuthError = useAppSelector(hasAuthErrorSelector);
  const errorMessage = useAppSelector(authErrorMessageSelector);
  const isSubmitting = useAppSelector(isAuthLoadingSelector);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    location.pathname === LOGIN && handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleLogout = () => {
    dispatch(logout());
    history.push(LOGIN);
  };

  const handleUsername = (e: React.ChangeEvent<HTMLInputElement>) =>
    setUsername(e.target.value.trim());

  const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) =>
    setPassword(e.target.value);

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    if (formRef?.current && formRef.current.reportValidity()) {
      e.preventDefault();
      await dispatch(loginUser({ username, password }));
      handleClose();
    }
  };

  return (
    <>
      <Dialog
        fullWidth
        disableBackdropClick
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="dialog-login-modal"
        open={open}>
        <DialogContent style={{ paddingTop: 0 }}>
          <form ref={formRef}>
            <div className={classes.flexCenterSpaceBetween}>
              <h3 className={classes.redTitle}>
                {strings.Votre_session_a_expiré}
              </h3>
              <Button className={classes.btnTransparent} onClick={handleLogout}>
                {strings.Se_deconnecter}
              </Button>
            </div>
            {hasAuthError && (
              <Alert
                variant="filled"
                severity="error"
                style={{ margin: '1rem 0' }}>
                {errorMessage}
              </Alert>
            )}
            <label className={classes.formInputTitle} htmlFor="email">
              {strings.Email}*
            </label>
            <TextField
              fullWidth
              required
              id="email"
              placeholder={`${strings.Veuillez_entrer_votre} ${strings.email}`}
              variant="outlined"
              margin="dense"
              value={username}
              onChange={handleUsername}
            />
            <label className={classes.formInputTitle} htmlFor="password">
              {strings.mot_de_passe}*
            </label>
            <TextField
              fullWidth
              required
              id="password"
              type="password"
              placeholder={`${strings.Veuillez_entrer_votre} ${strings.mot_de_passe}`}
              inputProps={{
                'aria-label': 'password'
              }}
              variant="outlined"
              margin="dense"
              value={password}
              onChange={handlePassword}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <div>
            <Alert style={{ marginBottom: '3px' }} severity="warning">
              <strong>{strings.Important_Si_après_la_reconnexion}</strong>,{' '}
              {strings.la_page_s_affiche_de_manière_incorrecte_ou_si_etc}
            </Alert>
            <Button
              disabled={!username || !password || isSubmitting}
              fullWidth
              className={classes.btnBlueSquare}
              aria-label="se-connecter"
              onClick={handleSubmit}>
              {isSubmitting
                ? strings.Vérification_en_cours
                : strings.Se_connecter}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}

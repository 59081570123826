import React from 'react';
import noData_icon from '~/assets/icons/no-data.svg';
import { strings } from '~/constants/strings';
import { useStyles } from './EmptyNoDataStyles';

const EmptyNoData = () => {
  const classes = useStyles();
  return (
    <div className={classes.flexCenterCenter}>
      <img src={noData_icon} width="42px" height="42px" alt="no data" />
      <p className={classes.noData}>{strings.Vide}</p>
    </div>
  );
};

export default EmptyNoData;

import React from 'react';
import pageNotFound404_img from '~/assets/images/404-page-not-found.svg';
import { defaultColor } from '~/constants/colors';
import { strings } from '~/constants/strings';

const NotFound = () => {
  return (
    <div style={{ textAlign: 'center', paddingTop: '3rem' }}>
      <h2 style={{ color: defaultColor.blue }}>
        {strings.Cette_page_ne_semble_pas_exister}
      </h2>
      <img
        src={pageNotFound404_img}
        width="400px"
        height="330px"
        alt="404 page not found"
      />
    </div>
  );
};

export default NotFound;

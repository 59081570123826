import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip, IconButton } from '@material-ui/core';
import EqualizerOutlinedIcon from '@material-ui/icons/EqualizerOutlined';
import { strings } from '~/constants/strings';
import { StatsIconButtonProps } from '../../Header.types';
import { STATS_RECAP } from '~/constants/internalLinks';

const StatsIconButton = ({ classes }: StatsIconButtonProps) => {
  return (
    <Tooltip title={strings.Statistique_détaillée} placement="bottom">
      <IconButton
        size="small"
        className={classes.iconStats}
        component={Link}
        to={STATS_RECAP}>
        <EqualizerOutlinedIcon
          style={{
            color: '#2196F3',
            fontSize: '28px'
          }}
        />
      </IconButton>
    </Tooltip>
  );
};

export default StatsIconButton;

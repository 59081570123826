import {
  CreateCommentDto,
  DateWithMessage,
  PagedResultDtoOfDisplayAgencyDto,
  PagedResultDtoOfDisplayAgencyWithLegalDocumentsDto,
  UpdateAgencyEligibiltyDto,
  LegalDocument,
  DisplayCommentDto,
  DisplayRevenuesDto,
  DisplayAgencyWithLegalDocumentsDto,
  CreateAgencyEmailDto,
  CreateAgencyGeoLocationDto,
  DisplayAgencyPerformanceDto
} from './api.types';
import http from './httpService';
import { AGENCIES } from '~/constants/api';
import useFetch from '~/hooks/useFetch';

interface AgenciesFindResponse {
  data: PagedResultDtoOfDisplayAgencyWithLegalDocumentsDto | null;
  isLoading: boolean;
  error: string | null;
}

export const useFetchAgencies = (): AgenciesFindResponse => {
  const { data, isLoading, error } = useFetch(
    `${AGENCIES}?IncludeLegalDocument=true`
  );
  return { data, isLoading, error };
};

export async function agencyAddComment(
  id: number,
  val: CreateCommentDto
): Promise<DisplayCommentDto> {
  const { data } = await http.post(`${AGENCIES}/${id}/comment`, val);
  return data;
}

export async function agencyAddVisitDate(
  id: number,
  val: { date: Date; message: string }
): Promise<DateWithMessage> {
  const { data } = await http.post(`${AGENCIES}/${id}/visit-date`, val);
  return data;
}

export async function agencyChangeEligibility(
  id: number,
  val: UpdateAgencyEligibiltyDto
): Promise<number> {
  const { data } = await http.patch(`${AGENCIES}/${id}/eligibility`, val);
  return data;
}

export async function agencyUpdateFormData(
  endpoint: string,
  formData: FormData
): Promise<LegalDocument> {
  const { data } = await http.put(endpoint, formData);
  return data;
}

export async function agencyUpdateDateOnly(
  endpoint: string,
  date: Date | null
): Promise<LegalDocument> {
  const { data } = await http.put(endpoint, {
    date
  });
  return data;
}

export async function agencyUpdateEmail(
  id: number,
  val: CreateAgencyEmailDto
): Promise<number> {
  const { data } = await http.put(`${AGENCIES}/${id}/email`, val);
  return data;
}

export async function agencyUpdateGeoLocation(
  id: number,
  val: CreateAgencyGeoLocationDto
): Promise<number> {
  const { data } = await http.put(`${AGENCIES}/${id}/geo-location`, val);
  return data;
}

export async function agencyDeleteFile(
  endpoint: string,
  fileId: string
): Promise<number> {
  const { data } = await http.delete(`${endpoint}/${fileId}`);
  return data;
}

export async function getEligibleAgencies(): Promise<PagedResultDtoOfDisplayAgencyDto> {
  const { data } = await http.get(`${AGENCIES}/eligible`);
  return data;
}

export async function getAgenciesWithoutLegalDocuments(): Promise<PagedResultDtoOfDisplayAgencyDto> {
  const { data } = await http.get(`${AGENCIES}?IncludeLegalDocument=false`);
  return data;
}

interface AgencyRevenuesResponse {
  agencyRevenues: DisplayRevenuesDto | null;
  isLoadingAgencyRevenues: boolean;
  agencyRevenuesError: string | null;
}

export const useFetchAgencyRevenues = (id: number): AgencyRevenuesResponse => {
  const {
    data: agencyRevenues,
    isLoading: isLoadingAgencyRevenues,
    error: agencyRevenuesError
  } = useFetch(`${AGENCIES}/${id}/revenues`);
  return { agencyRevenues, isLoadingAgencyRevenues, agencyRevenuesError };
};

interface AgencyFindResponse {
  agencyData: DisplayAgencyWithLegalDocumentsDto | null;
  isLoadingAgencyData: boolean;
  agencyDataError: string | null;
}

export const useFetchAgencyById = (id: number): AgencyFindResponse => {
  const {
    data: agencyData,
    isLoading: isLoadingAgencyData,
    error: agencyDataError
  } = useFetch(`${AGENCIES}/${id}?IncludeLegalDocument=false`);
  return {
    agencyData,
    isLoadingAgencyData,
    agencyDataError
  };
};

interface AgenciesPerformancesResponse {
  data: DisplayAgencyPerformanceDto | null;
  isLoading: boolean;
  error: string | null;
}

export const useFetchAgenciesPerformances = (
  id: number | null
): AgenciesPerformancesResponse => {
  const { data, isLoading, error } = useFetch(
    `${AGENCIES}/${id}/performance`,
    !!id
  );
  return { data, isLoading, error };
};

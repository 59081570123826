import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import LoadingSpinner from '~/components/LoadingSpinner/LoadingSpinner';
import { ACCESS_DENIED, LOGIN } from '~/constants/internalLinks';
import {
  isAuthenticatedSelector,
  isAuthLoadingSelector,
  isAdminSelector,
  userHasAccessTokenSelector
} from '~/redux/features/user/userAuthSlice';
import { useAppSelector } from '~/redux/redux-hooks';
import { PrivateRouteProps } from '../Routes.types';

// eslint-disable-next-line react/prop-types
const AdminRoute = ({ children, ...rest }: PrivateRouteProps) => {
  const location = useLocation();
  const isAuthenticated = useAppSelector(isAuthenticatedSelector);
  const isAdmin = useAppSelector(isAdminSelector);
  const isAuthLoading = useAppSelector(isAuthLoadingSelector);
  const userHasAccessToken = useAppSelector(userHasAccessTokenSelector);

  if (isAuthenticated && isAdmin) return <Route {...rest}>{children}</Route>;
  if (isAuthenticated && !isAdmin) return <Redirect to={ACCESS_DENIED} />;
  return (
    <>
      {!userHasAccessToken && !isAuthLoading && (
        <Redirect to={{ pathname: LOGIN, state: { from: location } }} />
      )}
      <LoadingSpinner />
    </>
  );
};

export default AdminRoute;

export const allowedDocType = [
  `image/png`,
  `image/jpeg`,
  `image/gif`,
  `application/pdf`,
  `text/plain`,
  `text/csv`,
  `application/msword`,
  `application/vnd.openxmlformats-officedocument.wordprocessingml.document`,
  `application/vnd.ms-excel`,
  `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`,
  `application/vnd.ms-powerpoint`,
  `application/vnd.openxmlformats-officedocument.presentationml.presentation`,
  'application/x-rar-compressed, application/octet-stream',
  'application/zip',
  'application/octet-stream',
  'application/x-zip-compressed',
  'multipart/x-zip',
  'application/x-7z-compressed'
];

export const allowedDocExtension = [
  'png',
  'jpeg',
  'jpg',
  'gif',
  'pdf',
  'txt',
  'csv',
  'doc',
  'docx',
  'xls',
  'xlsx',
  'ppt',
  'pptx',
  'rar',
  'zip',
  '7zip'
];

export const allowedImgType = [`image/png`, `image/jpeg`, `image/gif`];

export const allowedImgExtension = ['png', 'jpeg', 'jpg', 'gif'];

import React from 'react';
import { CircularProgress, IconButton, Tooltip } from '@material-ui/core';
import ReplayIcon from '@material-ui/icons/Replay';
import { ReloadAndMoreStatsProps } from '../../Header.types';
import upDownArrow_icon from '~/assets/icons/up-and-down-arrow.png';
import { strings } from '~/constants/strings';

const ReloadAndMoreStats = ({
  classes,
  handleDisplayMoreInfo,
  handleRefresh,
  intervalInSeconds,
  isLoading
}: ReloadAndMoreStatsProps) => {
  return (
    <div className={classes.iconRefreshAndMoreStats}>
      <IconButton
        aria-label="scroll-button"
        size="small"
        onClick={handleDisplayMoreInfo}>
        <img
          src={upDownArrow_icon}
          alt="up-down-arrow-scroll"
          width="20px"
          height="20px"
        />
      </IconButton>
      <Tooltip
        title={
          <div>
            <span>{strings.Actualiser_les_statistiques}.</span>
            <p style={{ margin: '3px 0 0 0' }}>
              {strings.Note}:{' '}
              {
                strings.Les_statistiques_sont_automatiquement_actualisees_tous_les
              }{' '}
              {intervalInSeconds / 60} {strings.minutes}
            </p>
          </div>
        }
        placement="bottom">
        <IconButton
          aria-label="refresh-button"
          size="small"
          onClick={handleRefresh}>
          {isLoading ? (
            <CircularProgress color="secondary" size="15px" />
          ) : (
            <ReplayIcon style={{ color: '#A1A1A1', fontSize: '18px' }} />
          )}
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default ReloadAndMoreStats;

import React from 'react';
import { strings } from '~/constants/strings';
import { TodoStatsProps } from '../Header.types';

const TodoStats = ({ classes, todoStats }: TodoStatsProps) => {
  return (
    <>
      {/* Planned Interventions */}
      <div className={classes.flexCenterCenter}>
        <p className={`${classes.headerNumber} ${classes.purple}`}>
          {todoStats?.interventionsToBePlanned ?? ''}
        </p>
        <p className={classes.numberDescription} style={{ maxWidth: '115px' }}>
          {strings.Interventions_en_cours}
        </p>
      </div>
      {/* quotes to sent */}
      <div className={classes.flexCenterCenter}>
        <p className={`${classes.headerNumber} ${classes.purple}`}>
          {todoStats?.quoteTodos ?? ''}
        </p>
        <p className={classes.numberDescription} style={{ maxWidth: '90px' }}>
          {strings.Devis_a_transmettre}
        </p>
      </div>
      {/* Visit reports to sent */}
      <div className={classes.flexCenterCenter}>
        <p className={`${classes.headerNumber} ${classes.purple}`}>
          {todoStats?.visitReportTodos ?? ''}
        </p>
        <p className={classes.numberDescription} style={{ maxWidth: '140px' }}>
          {strings.Rapports_de_visite_a_transmettre}
        </p>
      </div>
      {/* invocies to sent */}
      <div className={classes.flexCenterCenter}>
        <p className={`${classes.headerNumber} ${classes.purple}`}>
          {todoStats?.invoiceTodos ?? ''}
        </p>
        <p className={classes.numberDescription} style={{ maxWidth: '90px' }}>
          {strings.Factures_a_transmettre}
        </p>
      </div>
    </>
  );
};

export default TodoStats;

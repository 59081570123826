import { Month } from '~/services/api.types';
import { strings } from './strings';

export const ternary = {
  YES: 'YES',
  NO: 'NO'
};

export const months = [
  { value: Month.January, name: strings.Janvier },
  { value: Month.February, name: strings.Février },
  { value: Month.March, name: strings.Mars },
  { value: Month.April, name: strings.Avril },
  { value: Month.May, name: strings.Mai },
  { value: Month.June, name: strings.Juin },
  { value: Month.July, name: strings.Juillet },
  { value: Month.August, name: strings.Aout },
  { value: Month.September, name: strings.Septembre },
  { value: Month.October, name: strings.Octobre },
  { value: Month.November, name: strings.Novembre },
  { value: Month.December, name: strings.Décembre }
];

export const ATTILA_GC_SIRET = '532 319 878 00023';
export const ATTILA_GC_TVA_INTRA = 'FR 49 532 319 878';
export const ATTILA_GC_APE = '4391B';
export const ATTILA_GC_ADDRESS = '71-73 Rue André Coquillet 45200 MONTARGIS';
export const ATTILA_GC_PHONE = '02 38 85 68 88';
export const ATTILA_GC_FAX = '02 38 28 02 75';
export const ATTILA_GC_EMAIL = 'grands-comptes@attila.fr';
export const ATTILA_CAPITAL = '50.000€';

export const TEMPORARY_ID = 'temporaryId-';

export const START_DATE = 'start-date';
export const END_DATE = 'end-date';

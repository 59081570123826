import React, { useState } from 'react';
import { IconButton, Drawer, Button, Card } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import CloseIcon from '@material-ui/icons/Close';
import ClearIcon from '@material-ui/icons/Clear';
import { useStyles, BadgeRed } from './NavBarMenusStyles';
import { getTimeDifference } from '~/utils/shared';
import { strings } from '~/constants/strings';
import EmptyNoData from '../../EmptyNoData';

const NotificationNavMenu = () => {
  const classes = useStyles();
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [notifications, setNotifications] = useState<any[]>([]);

  const handleDrawerOpen = () => {
    setIsPanelOpen(true);
  };

  const handleDrawerClose = () => {
    setIsPanelOpen(false);
  };

  const handleRemoveNotification = (id: number) => {
    const filtered = notifications.filter((el) => el.id !== id);
    setNotifications(filtered);
  };

  const handleClearAll = () => {
    setNotifications([]);
  };

  return (
    <>
      <IconButton
        aria-label="notification"
        size="small"
        className={classes.iconNotification}
        onClick={handleDrawerOpen}>
        <BadgeRed color="primary" badgeContent={notifications?.length}>
          <NotificationsIcon
            style={{
              color: '#FFC107',
              fontSize: '30px',
              marginRight: '-3px'
            }}
          />
        </BadgeRed>
      </IconButton>
      <Drawer
        variant="temporary"
        anchor="right"
        BackdropProps={{ invisible: true }}
        open={isPanelOpen}
        onClose={handleDrawerClose}
        ModalProps={{
          keepMounted: true
        }}>
        <div>
          <div className={classes.notificationBar}>
            <div className={classes.notificationCardDelete}>
              <IconButton onClick={handleDrawerClose} size="small">
                <CloseIcon fontSize="small" color="secondary" />
              </IconButton>
            </div>
            <div
              className={classes.flexCenterCenter}
              style={{ height: '35px' }}>
              <NotificationsIcon
                style={{
                  color: '#FFC107',
                  fontSize: '30px',
                  marginRight: '5px'
                }}
              />
              <h3>{strings.Centre_de_notifications}</h3>
            </div>
          </div>
          {!notifications?.length && <EmptyNoData />}
          {notifications?.map((notification) => (
            <div key={notification.id} style={{ padding: '5px' }}>
              <div className={classes.notificationCardDelete}>
                <IconButton
                  size="small"
                  onClick={() => handleRemoveNotification(notification.id)}>
                  <ClearIcon fontSize="small" />
                </IconButton>
              </div>
              <Card className={classes.notificationCard}>
                <div className={classes.notificationCardHeader}>
                  <NotificationsIcon fontSize="small" />
                  <small>
                    {getTimeDifference(new Date(notification.date))}
                  </small>
                </div>
                <div style={{ padding: '1px 3px 5px 5px' }}>
                  <p>{notification.title}</p>
                  <small>{notification.message}</small>
                </div>
              </Card>
            </div>
          ))}
          {!!notifications?.length && (
            <div className={classes.flexCenterCenter}>
              <Button
                className={classes.btnTransparent}
                onClick={handleClearAll}>
                {strings.Effacer_tout}
              </Button>
            </div>
          )}
        </div>
      </Drawer>
    </>
  );
};

export default NotificationNavMenu;

import React, { useState, useEffect } from 'react';
import { withRouter /* , useHistory */ } from 'react-router-dom';
import { ACCESS_TOKEN_EXPIRED } from '~/constants/auth';
// import { getCurrentUser } from '~/services/auth';
import ReLoginModal from './ReLoginModal';
import { eventBus } from '~/utils/shared';

const AuthVerify = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    eventBus.on(ACCESS_TOKEN_EXPIRED, () => {
      setOpen(true);
    });
    return () => {
      eventBus.remove(ACCESS_TOKEN_EXPIRED);
    };
  }, []);

  const handleClose = () => {
    setOpen(false);
  };
  // if you also want detection when changing the route
  // const history = useHistory();
  // history.listen(() => {
  //   const currentUser: any = getCurrentUser();
  //   const exp = currentUser?.exp;

  //   if (exp * 1000 < Date.now()) {
  //     setOpen(true);
  //   }
  // });
  return <>{open && <ReLoginModal open={open} handleClose={handleClose} />}</>;
};
export default withRouter(AuthVerify);

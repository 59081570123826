import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import createFilter from 'redux-persist-transform-filter';
import { combineReducers } from 'redux';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  persistStore
} from 'redux-persist';
// import logger from 'redux-logger';
import agenciesReducer from './features/agencies/agenciesSlice';
import userPreferenceReducer from './features/user/userPreferenceSlice';
import userAuthReducer from './features/user/userAuthSlice';
import gcAgentsReducer from './features/gcAgents/gcAgentsSlice';

const reducers = combineReducers({
  userAuth: userAuthReducer,
  agencies: agenciesReducer,
  gcAgents: gcAgentsReducer,
  userPreference: userPreferenceReducer
});

// "auth" is a nested object to persist it, we have to use createFilter
// otherwise, we will have a bad conversion
const userAuthFilter = createFilter('userAuth', ['auth']);

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['userPreference', 'userAuth'],
  transforms: [userAuthFilter]
};

export type RootReducer = ReturnType<typeof reducers>;

const persistedReducer = persistReducer<RootReducer>(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }) // .concat(logger) // to enable logger
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

// eslint-disable-next-line
export const persistor = persistStore(store);

export default store;

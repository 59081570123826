import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Grid, TextField } from '@material-ui/core';
import logo from '~/assets/icons/logo-attila-gc-red.svg';
import { useStyles } from './HeaderStyles';
import { strings } from '~/constants/strings';
import { HOME } from '~/constants/internalLinks';
import DocumentTitle from '../DocumentTitle';
import ProfileNavMenu from './NavBarMenus/ProfileNavMenu';
import NotificationNavMenu from './NavBarMenus/NotificationNavMenu';
import BillManagerNavMenu from './NavBarMenus/BillManagerNavMenu';
// import ExpandUpDownIconButton from './ExpandUpDownIconButton';
import Stats from './Stats';
import StatsIconButton from './Stats/StatsIcons/StatsIconButton';
import ReloadAndMoreStats from './Stats/StatsIcons/ReloadAndMoreStats';
import { DisplayRevenuesDto, DisplayTodoStatsDto } from '~/services/api.types';
import {
  getInterventionsRevenues,
  getTodoStats
} from '~/services/interventions';
import { useIntervalWhen } from '~/hooks/useInterval';
import getEnv from '~/utils/env';

const Navbar = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [hasToDisplayMoreInfo, setHasDisplayMoreInfo] = useState(false);
  const [interventionsRevenues, setInterventionsRevenues] =
    useState<DisplayRevenuesDto | null>(null);
  const [todoStats, setTodoStats] = useState<DisplayTodoStatsDto | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchInterventionsRevenues = async () => {
    try {
      setIsLoading(true);
      const [resInterventionsRevenues, resTodoStats] = await Promise.all([
        getInterventionsRevenues(),
        getTodoStats()
      ]);
      setInterventionsRevenues(resInterventionsRevenues);
      setTodoStats(resTodoStats);
    } catch (ex) {
      //
    } finally {
      setIsLoading(false);
    }
  };

  const intervalInSeconds =
    Number(getEnv('NAVBAR_STATS_REFRESH_PERIOD_IN_SEC')) || 300; // by default 300 seconde (5 minutes)

  useIntervalWhen(
    () => {
      fetchInterventionsRevenues();
    },
    intervalInSeconds * 1000 // in milliseconds
  );

  useEffect(() => {
    fetchInterventionsRevenues();
  }, []);

  const handleDisplayMoreInfo = () => {
    setHasDisplayMoreInfo((prev) => !prev);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleRefresh = () => {
    fetchInterventionsRevenues();
  };

  const classes = useStyles();

  return (
    <div className={classes.navbar}>
      <DocumentTitle />
      <Grid
        className={classes.grid}
        container
        direction="row"
        justify="space-between"
        alignItems="flex-start"
        spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <div className={classes.flexCenter}>
            <Link to={HOME}>
              <img src={logo} alt="logo" className={classes.logo} />
            </Link>
            <div className={classes.searchBar}>
              <TextField
                className={classes.searchInput}
                color="secondary"
                placeholder={strings.Rechercher}
                variant="outlined"
                size="small"
                id="input-search-header"
                value={searchQuery}
                onChange={handleSearch}
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={false} md={8} lg={8} xl={8}>
          <Stats
            classes={classes}
            interventionsRevenues={interventionsRevenues}
            todoStats={todoStats}
            hasToDisplayMoreInfo={hasToDisplayMoreInfo}
          />
        </Grid>
      </Grid>
      <div>
        <ReloadAndMoreStats
          classes={classes}
          handleDisplayMoreInfo={handleDisplayMoreInfo}
          handleRefresh={handleRefresh}
          intervalInSeconds={intervalInSeconds}
          isLoading={isLoading}
        />
        <StatsIconButton classes={classes} />
        <BillManagerNavMenu />
        <NotificationNavMenu />
        <div className={classes.avatar}>
          <ProfileNavMenu />
        </div>
      </div>
    </div>
  );
};

export default Navbar;

import React, { memo } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { getColor } from './shared';
import { useStyles } from './MyAvatarStyles';
import { getUserFullnameFirstLetter } from '~/containers/shared/contact/contactUtils';
import { userInfoSelector } from '~/redux/features/user/userAuthSlice';
import { useAppSelector } from '~/redux/redux-hooks';

const MyAvatarChip = () => {
  const classes = useStyles();
  const currentUser = useAppSelector(userInfoSelector);
  const firstLetter = currentUser?.firstName?.charAt(0)?.toUpperCase();
  const color = getColor(firstLetter);

  return (
    <div className={classes.flexCenterCenter} style={{ padding: '1rem' }}>
      <Avatar
        variant="rounded"
        data-testid="my-avatar-chip"
        style={{
          backgroundColor: color,
          cursor: 'pointer',
          width: '43px',
          height: '43px',
          fontSize: '23px'
        }}>
        {getUserFullnameFirstLetter(currentUser)}
      </Avatar>
      <p className={classes.name} style={{ color }}>
        {currentUser?.firstName} {currentUser?.lastName}
      </p>
    </div>
  );
};
export default memo(MyAvatarChip);

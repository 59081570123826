import { DIRECTORY_USERS, DIRECTORY_ACCOUNTS } from '~/constants/api';
import useFetch from '~/hooks/useFetch';
import {
  DisplayUserDtoPagedResultDto,
  DisplayUserDto,
  CreateAccountDto
} from './directoryApi.types';
import http from './httpService';

export async function getGcAgents(): Promise<DisplayUserDtoPagedResultDto> {
  const { data } = await http.get(`${DIRECTORY_USERS}`);
  return data;
}

export async function createGcAgent(
  val: CreateAccountDto
): Promise<DisplayUserDto> {
  const { data } = await http.post(`${DIRECTORY_ACCOUNTS}`, val);
  return data;
}

interface GcAgentsFindResponse {
  gcUsersData: DisplayUserDtoPagedResultDto | null;
  isLoading: boolean;
  error: string | null;
}

export const useFetchGcAgents = (
  hasToRefetch: boolean
): GcAgentsFindResponse => {
  const {
    data: gcUsersData,
    isLoading,
    error
  } = useFetch(DIRECTORY_USERS, true, hasToRefetch);
  return { gcUsersData, isLoading, error };
};

import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  box: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row'
  },
  btnBlueSquare: {
    marginTop: '1rem',
    ...theme.mixins.btnBlueSquare,
    ...theme.typography.btnSquare
  },
  formInputTitle: {
    ...theme.typography.formInputTitle,
    whiteSpace: 'nowrap',
    display: 'inline-block',
    marginTop: '12px',
    '&:first-letter': {
      textTransform: 'capitalize'
    }
  },
  flexCenterCenter: {
    ...theme.mixins.flexCenterCenter
  },
  flexCenterSpaceBetween: {
    ...theme.mixins.flexCenterSpaceBetween
  },
  divider: {
    width: '1px',
    height: '600px',
    background: '#ddd',
    [theme.breakpoints.down('xs')]: {
      height: '0'
    }
  },
  btnTransparent: {
    margin: '5px 0',
    float: 'right',
    ...theme.mixins.btnTransparent,
    ...theme.typography.btnTransparent,
    color: theme.palette.common.blue
  },
  modalTitle: {
    ...theme.typography.modalTitle
  },
  redTitle: {
    textAlign: 'center',
    color: 'red'
  }
}));

export const defaultColor = {
  black: '#000000',
  white: '#FFFFFF',
  blue: '#72B9B9',
  blueKingstonAqua: '#85CCCC',
  blueAquaVeryLight: '#bbeded',
  blueDark: '#7796c5',
  strongCyan: '#6FCBB8',
  pastelLightBlue: '#FAFBFD',
  celestial: '#99B5DB',
  gray: '#848484',
  grayF3: '#f3f3f3',
  grayF9: '#F9F9F9',
  grayA8: '#A8A6A6',
  gray70: '#707070',
  grayMaterial: '#E0E0E0',
  blackMaterial: '#000000DE',
  lightGray: '#CCCCCC',
  veryLightGray: '#CFCFCF',
  blackGray: '#575555',
  orange: '#EA9648',
  paleOrange: '#FFB979',
  orangePeel: '#FF9900',
  red: '#D48F8F',
  redCoralTree: '#AD7575',
  veryLightRed: '#FFCCCB',
  redVistaWhite: '#fAE3E5',
  green: '#99CC99',
  veryLightGreen: '#C9E0C9',
  greenPistachio: '#8FD883',
  greenLightSea: '#20B2AA',
  green42: '#42AD42',
  green90: '#90c394',
  yellow: '#E8BA2F',
  softPurple: '#A89FCF',
  verySoftPurple: '#E0DDEB',
  cornflowerBlue: '#5895e8'
};

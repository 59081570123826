import { createSlice } from '@reduxjs/toolkit';
import { REDUX_PERSIST_KEY } from '~/constants/storageKeys';
import { RootState } from '../../store';

interface State {
  isAdvancedSearchOpen: boolean;
  calendarOptions: {
    isCalendarOpen: boolean;
    isWeekendHidden: boolean;
    isExpanded: boolean;
    hasToShowOnlyCurrentUserEvents: boolean;
  };
}

const initialState: State = {
  isAdvancedSearchOpen: false,
  calendarOptions: {
    isCalendarOpen: false,
    isWeekendHidden: true,
    isExpanded: false,
    hasToShowOnlyCurrentUserEvents: false
  }
};

export const clearuserPreferencesStorage = async () => {
  localStorage.removeItem(REDUX_PERSIST_KEY);
};

const userPreferenceSlice = createSlice({
  name: 'userPreference',
  initialState,
  reducers: {
    resetUserPreferences: (state) => {
      Object.assign(state, initialState);
      clearuserPreferencesStorage();
    },
    openAdvancedSearch: (state) => {
      state.isAdvancedSearchOpen = true;
    },
    closeAdvancedSearch: (state) => {
      state.isAdvancedSearchOpen = false;
    },
    openCalendar: (state) => {
      state.calendarOptions.isCalendarOpen = true;
    },
    closeCalendar: (state) => {
      state.calendarOptions.isCalendarOpen = false;
    },
    showWeekendOnCalendar: (state) => {
      state.calendarOptions.isWeekendHidden = false;
    },
    hideWeekendOnCalendar: (state) => {
      state.calendarOptions.isWeekendHidden = true;
    },
    expandCalendar: (state) => {
      state.calendarOptions.isExpanded = true;
    },
    reduceCalendar: (state) => {
      state.calendarOptions.isExpanded = false;
    },
    showOnlyCurrentUserEvents: (state) => {
      state.calendarOptions.hasToShowOnlyCurrentUserEvents = true;
    },
    showAllUsersEvents: (state) => {
      state.calendarOptions.hasToShowOnlyCurrentUserEvents = false;
    }
  }
});

// we use create selector to get cached fast response (it like reselect)
export const isAdvancedSearchOpenSelector = (state: RootState) =>
  state.userPreference.isAdvancedSearchOpen;

export const isCalendarOpenSelector = (state: RootState) =>
  state.userPreference.calendarOptions.isCalendarOpen;

export const isCalendarWeekendHiddenSelector = (state: RootState) =>
  state.userPreference.calendarOptions.isWeekendHidden;

export const isCalendarExpandedSelector = (state: RootState) =>
  state.userPreference.calendarOptions.isExpanded;

export const hasToShowOnlyCurrentUserEventsSelector = (state: RootState) =>
  state.userPreference.calendarOptions.hasToShowOnlyCurrentUserEvents;

export const {
  resetUserPreferences,
  openAdvancedSearch,
  closeAdvancedSearch,
  openCalendar,
  closeCalendar,
  showWeekendOnCalendar,
  hideWeekendOnCalendar,
  expandCalendar,
  reduceCalendar,
  showOnlyCurrentUserEvents,
  showAllUsersEvents
} = userPreferenceSlice.actions;
export default userPreferenceSlice.reducer;

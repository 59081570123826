import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosPrivate } from '~/services/httpService';
import {
  TOKEN_KEY,
  REDUX_PERSIST_KEY,
  REFRESH_TOKEN_KEY
} from '~/constants/storageKeys';
import { strings } from '~/constants/strings';
import { decodeToken, getUserAccountMe, userLogin } from '~/services/auth';
import { RootState } from '../../store';

// TODO: temporary, should change in API
export enum Roles {
  Admin = 'admin',
  User = 'gc-user',
  Accountant = 'gc-accountant'
}

export type UserInfo = {
  id: number | undefined | null;
  firstName: string | undefined | null;
  lastName: string | undefined | null;
  email: string | undefined | null;
};
interface State {
  isAuthenticated: boolean;
  isAuthLoading: boolean;
  hasError: boolean;
  auth: {
    hasAccessToken: boolean;
    userInfo: UserInfo | null;
    role: Roles | null;
  };
  errorMessage: string;
}

const initialState: State = {
  isAuthenticated: false,
  isAuthLoading: false,
  auth: {
    hasAccessToken: false,
    userInfo: null,
    role: null
  },
  hasError: false,
  errorMessage: ''
};

export const loginUser = createAsyncThunk(
  'userAuth/userLogin',
  async ({ username, password }: any) => {
    const res = await userLogin({ username, password });
    if (res?.access_token) {
      localStorage.setItem(TOKEN_KEY, res.access_token);
      if (res?.refresh_token) {
        localStorage.setItem(REFRESH_TOKEN_KEY, res.refresh_token);
      }
      axiosPrivate.defaults.headers.common[
        // eslint-disable-next-line dot-notation
        'Authorization'
      ] = `Bearer ${res.access_token}`;
      const decoded: any = decodeToken(res.access_token);
      const role = decoded?.role;
      if (Object.values(Roles).includes(role)) {
        return role;
      }
    }
    return null;
  }
);

export const fetchUserMe = createAsyncThunk(
  'userAuth/userMe',
  async (_undefined, { rejectWithValue }) => {
    const response = await getUserAccountMe();
    const userInfo = response.data;
    if (response.status === 200) {
      return userInfo;
    }
    return rejectWithValue('error');
  }
);

export const clearStorage = async () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
  localStorage.removeItem(REDUX_PERSIST_KEY);
};

const userAuthSlice = createSlice({
  name: 'userAuth',
  initialState,
  reducers: {
    logout: (state) => {
      Object.assign(state, initialState);
      clearStorage();
    }
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.auth.hasAccessToken = true;
      state.auth.role = action.payload;
      state.isAuthLoading = false;
      state.hasError = false;
      state.errorMessage = '';
      state.isAuthenticated = true;
    });
    builder.addCase(loginUser.pending, (state) => {
      state.isAuthLoading = true;
    });
    builder.addCase(loginUser.rejected, (state, { error }) => {
      state.auth.hasAccessToken = false;
      state.isAuthenticated = false;
      state.isAuthLoading = false;
      state.hasError = true;
      state.errorMessage = error?.message?.match(/4\d{2}/) // match 4xx error
        ? strings.email_ou_mot_de_passe_incorrect
        : strings.Erreur_serveur;
    });
    builder.addCase(fetchUserMe.fulfilled, (state, { payload }) => {
      state.auth.hasAccessToken = true;
      state.auth.userInfo = {
        id: payload.id,
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email
      };
      state.isAuthenticated = true;
      state.isAuthLoading = false;
      state.hasError = false;
      state.errorMessage = '';
    });
    builder.addCase(fetchUserMe.pending, (state) => {
      state.isAuthLoading = true;
    });
    builder.addCase(fetchUserMe.rejected, (state) => {
      state.auth.hasAccessToken = false;
      state.isAuthenticated = false;
      state.isAuthLoading = false;
      state.hasError = true;
      state.errorMessage = strings.Votre_session_a_expiré;
      // clearStorage();
    });
  }
});

export const userRoleSelector = (state: RootState) => state.userAuth.auth.role;
export const userHasAccessTokenSelector = (state: RootState) =>
  state.userAuth.auth.hasAccessToken;
export const isAuthenticatedSelector = (state: RootState) =>
  state.userAuth.isAuthenticated;
export const isAdminSelector = (state: RootState) =>
  state.userAuth.auth.role === Roles.Admin;
export const isAuthLoadingSelector = (state: RootState) =>
  state.userAuth.isAuthLoading;
export const hasAuthErrorSelector = (state: RootState) =>
  state.userAuth.hasError;
export const authErrorMessageSelector = (state: RootState) =>
  state.userAuth.errorMessage;
export const userInfoSelector = (state: RootState) =>
  state.userAuth.auth.userInfo;

export const { logout } = userAuthSlice.actions;
export default userAuthSlice.reducer;
